@import "_default.scss_include-mixins";

.color-white {
	color:#fff !important;
}

.color-black {
	color:#000 !important;
}

.color-orange {
	color:#ff6400 !important;
}