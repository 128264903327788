@import "_default.scss_include-mixins";

/*
Button 1
Button 1
Button 1
Button 1
*/
.button-1 {

	display:inline-block;

	padding:1.0vw;
	padding-top:0.6vw;
	padding-bottom:0.6vw;	
	border-radius:50vh;

	background-color:#fff;

	transition:0.3s transform;

	// border-bottom:0.2vw solid rgba(7, 7, 7, 1);

	@include md {
		padding:10px;
		padding-left:20px;
		padding-right:20px;
	}

	.button-inside {

		float:left;
		width:100%;
		margin-top:0px;

		.button-icon {
			float:right;
			margin-top:10px;
			margin-left:10px;

			svg {
				float:left;
				width:20px;
				height:20px;
				fill:#fff;
			}
		}

		.button-label {
			float:left;
			width:auto !important;
			text-align:left;
			font-size:1.2vw;
			font-weight:500;
			color:#000;
			
			@include md {
				font-size:14px;
			}
		}

		&.no-icon {
			.button-label {
				margin-left:0;
			}
		}

	}

	&:hover {

		//background-color:#2A4F9C;
		transform:scale(1.05);

		.label {
			// text-decoration:underline;
		}

		.icon {
			// margin-left:30px;
		}
		
	}

}