@import "_default.scss_include-mixins";

/*
Version 0.160

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	- 20-07-2024
	- [ADDED] Popup default CSS now here
2020
	25-08-2020
	- [CHANGE] SCSS Style
	22-04-2020
	- [ADDED] Change some stuff.
	
2019
	24-05-2019
	- [ADDED] Popup is now relative
2018
	29-12-2018
	- [CHANGED] Added display:none to .popup-scroller.
	10-12-2018
	- [ADDED] Some CSS for .popup-scroller.
	09-12-2018
	- [ADDED] Added responsive .close.
	04-09-2018
	- [CHANGED] Changed some CSS things for better popup scrolling.
	28-02-2018
	- [ADDED] A new extra div is added, .popup-view for preventing the body to scroll when popup is higher then the window height.
2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* 
.popup-outside {
	display:none;
	position:fixed;
	left:0;
	top:0;
	bottom:0;
	right:0;
	z-index:8000;
}
*/

.popup {
	position:relative;
}

.close {
	position:absolute;
	top:-20px;
	right:-20px;
	width:50px;
	height:50px;
	cursor:pointer;
	z-index:10;
	background-color:#fff;
	border-radius:50%;
	box-shadow:0 5px 5px rgba(0,0,0,0.3);

	@include md {
		right:0;
	}

	.lines {
		float:left;
		width:100%;
		height:100%;
		height:100%;

		.line {
			position:absolute;
			margin-top:0;
			margin-left:0;
			width:100%;
			height:5px;
			background-color:#fff;

			&.line-1 {
				margin-top:19px;
				transform:rotate(-45deg);
			}
			&.line-2 {
				margin-top:19px;
				transform:rotate(-135deg);
			}
		}

	}

}

.popup-scroller {
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:auto;
	background-color:rgba(0,0,0,0.90);
	z-index:8500;
}

	.popup-loader {
		display:none;
		position:absolute;
		top:0;
		left:0;
		left:-9999px;
		z-index:200;

		@include sm {
			top:20px;
		}
	}

	.popup-background { 
		display:none;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:100;
		overflow:auto;
		cursor:pointer;
	}

/*
Popup inside
*/
.popup {

	.header {
		clear:both;
		float:left;
		width:100%;
	}
		
	.content {
		clear:both;
		float:left;
		width:100%;
		margin-top:10px;
	}

}

/*
Popup: Default
*/
.popup--default {

	position:relative;
	float:left;
	width::55vw;

	@include md {
		width:90vw;
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:40px;
		padding-bottom:30px;
		padding-top:30px;
		border-radius:15px;
		text-align:center;

		@include md {
			padding:20px;
		}

		h2 {
			margin-top:1vw;
			margin-bottom:1vw;
			font-size:2.5vw;

			@include md {
				font-size:20px;
				margin-top:10vw;
			}
		}

		p {
			margin-top:2vw;
			padding-bottom:1vw;
		}

		.form {
			padding-left:3vw;
			padding-right:3vw;

			input,
			select {
				font-size:1vw;
				padding:1vw;
				border-radius:50px;

				@include md {
					font-size:15px;
					padding:10px;
					padding-left:20px;
					padding-right:20px;
				}
			}

			.element-row {

				margin-top:20px;

				@include sm {
					margin-top:10px;
				}
			}

		}

	}

}