@import "_default.scss_include-mixins";

.page.home {

   float:left;
   width:100vw;
   height:100vh;
   background: rgb(255,108,0);
   background: radial-gradient(circle, rgba(255,108,0,1) 0%, rgba(198,52,1,1) 100%); 

   .logo {
      position:fixed;
      top:50%;
      left:50%;
      width:200px;
      transform:translate(-50%, -50%);
   }
   
}