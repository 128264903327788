@import "_default.scss_include-mixins";

/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	- [CHANGE] SCSS Style
2017
	19-10-2017
	- [INFO] This file has born!

!Wishlist

	No wishes
 
*/

/* NOTIFIER */
/* NOTIFIER */
/* NOTIFIER */
/* NOTIFIER */

.notifier {
	display:none;
	position:fixed;
	left:0;
	top:0;
	width:100%;
	text-align:center;
	z-index:8000;
	
	.notifier-message {
		display:inline-block;
		padding-top:5px;
		padding-bottom:5px;
		padding-left:10px;
		padding-right:10px;
		color:#000;
		font-size:12px;
		text-align:center;
		background-color:#fff;
		border-radius: 3px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		box-shadow:0 0 8px rgba(0,0,0,0.4);
	}

}