@import "_default.scss_include-mixins";

body {
	font-family: 'DinPro', sans-serif;
	color:#fff;
	background-color:#fff;
	overflow-x:hidden;

	&.no-scroll {
		overflow:hidden;
	}
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

video {
    border: none;
    outline: none;
}

/*
Lazy Load
*/
.go-img-preload {
	float:left;
	background-size:60px;
	// filter: blur(0.5rem);	
	min-height:80vh;	
	opacity:0.2;

	@include md {
		background-size:30px;
		min-height:40vh;
	}

}

.go-lazyloaded {
	filter:none;
}


/*
Rest
Rest
Rest
Rest
*/
.rest {
	float:left;
	width:100vw;

	.title {
		float:left;
		width:100%;
		margin-top:8vw;
		margin-bottom:2vw;
		
		@include md {
			margin-top:40px;
			margin-bottom:20px;
		}

		.textblock-1 {
			color:#605e59;	
			h2 {
				font-size: 1.5vw;
				line-height: 2.5vw;
				font-weight:normal;

				@include md {
					font-size:24px;
					line-height:24px;
					font-weight:bold;
					color:#605e59;		
				}
			}
		}
	}

	/*
	Case Item 
	*/
	.case-item {
	   float:left;
	   position:relative;
	   width:100%;
	   padding-top:100%;

	   &:hover {
		  .hover {
			 display:block;
			 opacity:1;
		  }
	   }

	   .image {
		  position:absolute;
		  top:0;
		  left:0;
		  width:100%;
		  height:100%;
		  background-size:cover;
		  background-position:center;
	   }

	   .hover {
		  transition:all 0.2s ease;
		  opacity:0;

		  .hover-overlay {
			 position:absolute;
			 top:0;
			 left:0;
			 width:100%;
			 height:100%;
			 background-color:rgba(255,255,255,0.8);
			 z-index:1;
		  }

		  .hover-text {
			 position:absolute;
			 top:50%;
			 left:50%;
			 transform:translate(-50%, -50%);
			 color:#fff;
			 text-align:center;
			 z-index:2;
 
			 h2 {
				font-size:1.8vw;
				font-weight:bold;
				letter-spacing:0.2vw;
				color:#000;
				margin:0;
				padding:0;

				@include md {
				   font-size:16px;
				   line-height:20px;
				}

			 }
			 h3 {
				font-size:1.5vw;
				line-height:2.2vw;
				font-weight:normal;
				color:#000;
				margin:0;
				padding:0;
				margin-top:0.5vw;

				@include md {
				   font-size:16px;
				   line-height:20px;
				}

			 }
 
		  }
		  
	   }

	}

}