@import "_default.scss_include-mixins";

.modal--error {
	position:absolute;
	width:290px;
	z-index:10000;
	background-color:#fff;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
	border-radius:5px;
	overflow:hidden;
}