@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	h1 {
		font-size:1.5vw;
		line-height:2vw;
		padding:0;
		margin:0;
		margin-bottom:1.5vw;
		font-weight:bold;
	}

	h2 {
		font-size:1.5vw;
		line-height:2vw;
		padding:0;
		margin:0;
		margin-bottom:1.5vw;
		font-weight:bold;

	}

	h3 {
		font-size:1.4vw;
		line-height:2vw;
		padding:0;
		margin:0;
		margin-bottom:1.5vw;
		font-weight:bold;

	}

	p,
	.p {
		float:left;
		width:100%;
		font-size:1.5vw;
		line-height:2.5vw;
		margin:0;
		color: #888;

		// @include xl2 {
		// 	font-size:1.5vw;
		// 	line-height:2.5vw
		// }

		&.smaller {
			font-size:1.2vw;
			line-height:1.5vw;

			@include md {
				font-size:13px;
				line-height:18px;
			}

		}

		@include md {
			font-size:16px;
			line-height:20px;
		}

	}

	a {
		color:#1a48bd;
		text-decoration:underline;
	}

	ul  {

		float:left;
		width:100%;
		font-size:1.5vw;
		line-height:2.5vw;

		@include md {
			font-size:18px;
			line-height:28px;
		}

		li {
			float:left;
			width:100%;
			margin:0;
			color:#000;

			// @include xl2 {
			// 	font-size:1.2vw;
			// 	line-height:2.2vw;
			// }

		}

	}


}